import React,{ Component, useState, useEffect, useRef } from 'react'
import axios from 'axios';
import Header from "../header"
import "./css/music.css"
import { TagList } from "../common/tag_list"
import { TagEditList } from "../common/tag_edit_list"
import Button from '@mui/material/Button';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faShare } from "@fortawesome/free-solid-svg-icons";

const server = axios.create({
  baseURL: 'https://walmates.com',
});

function objectToQueryString(obj) {
  const keyValuePairs = [];
  
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Encode the key and value to handle special characters
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      keyValuePairs.push(`${encodedKey}=${encodedValue}`);
    }
  }
  
  return keyValuePairs.join('&');
}

const MusicWidget = (props) => {
  const [tracks, setTracks] = useState([]);
  const [play_track, setPlayLocalTrack] = useState(null);

  const initializeTracks = (songs) => {
    setTracks(songs)
  }

  const addTrack = (track) => {
    console.log("Adding new Track: ", track.title)
    setTracks([track, ...tracks]);
  }
  const playLocalTrack = (track) => {
    setPlayLocalTrack(track)
  }

  const handleLocalSearch = (keyword, setLocalSearchResult) => {
    const lowercaseKeyword = keyword.toLowerCase();

    // Split the keyword into an array of substrings separated by spaces
    const keywordSubstrings = lowercaseKeyword.split(' ');

    // Filter the tracks based on whether any of the keyword substrings exist in title or artist
    const filteredTracks = tracks.filter((track) => {
      const lowercaseTitle = track.title.toLowerCase();
      const lowercaseArtist = track.artist.toLowerCase();

      // Check if any of the keyword substrings exist in title or artist
      return keywordSubstrings.every((substring) =>
        lowercaseTitle.includes(substring) || lowercaseArtist.includes(substring)
      );
    });

    // Update the state with the filtered tracks
    console.log("local Search Result:", filteredTracks)
    setLocalSearchResult(filteredTracks);
  }

  return (
    <>
      <div className="vault_table">
      <SearchWidget addTrack={addTrack} handleLocalSearch={handleLocalSearch} playLocalTrack={playLocalTrack}/>
      <TracksWidget initializeTracks={initializeTracks} tracks={tracks}  play_track={play_track}/>
      </div>
    </>
  );
}
export default MusicWidget;

const TracksWidget = (props) => {
  const [initialized, setInitialized] = useState(false)
  const {initializeTracks, tracks,  play_track } = props;
  const [tags, setTags] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [nowPlayingIndex, setNowPlayingIndex] = useState(-1);
  const [nowPlayingVideoId, setNowPlayingVideoId] = useState(null);
  const [nowPlayingTitle, setNowPlayingTitle] = useState('');
  const [nowPlayingArtist, setNowPlayingArtist] = useState('');
  const [nowPlayingThumbnail, setNowPlayingThumbnail] = useState('')
  const [tracksShuffled, setTracksShuffled] = useState(false)
  const [autoPlay, setAutoPlay] = useState(true)
  const [device, setDevice] = useState('mobile');

  const [audioSrc, setAudioSrc] = useState('');
  const cachedTracksRef = useRef({}); 
  const [isPlaying, setIsPlaying] = useState(false);
  const audioElementRef = useRef(null);

    useEffect(() => {
    const checkDevice = () => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        setDevice('mobile');
      } else {
        setDevice('desktop');
      }
    };

    checkDevice(); // Initial check
    window.addEventListener('resize', checkDevice); // Listen for window resize
    return () => window.removeEventListener('resize', checkDevice); // Cleanup
  }, []);

  useEffect(() => {
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');

    server.get(`/api/music/getTags?user_id=${user_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log("User Tags", result.data)
      setTags(result.data)
    })
    .catch(err => {
      console.error("Error toget tracks:  error:", err);
    }); 
  }, []);

  useEffect(() => {
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');

    server.get(`/api/music/getTracks?user_id=${user_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      // console.log("User Tracks", result);
      initializeTracks(result.data);
      setInitialized(true)
    })
    .catch(err => {
      console.error("Error to search ", keyword, " error:", err);
    });

  }, []);

    useEffect(() => {
        if (tracks.length > 0 && autoPlay) {
          setAutoPlay(false)
          const lastVideoId = localStorage.getItem("walmates-now-playing-video-id");
          if (lastVideoId) {
            const index = tracks.findIndex((track) => track.video_id === lastVideoId);
            if (index >= 0 && index < tracks.length) {
              console.log("Found the previously played track:", lastVideoId, " at index:", index, ", autoplay same track");
              handlePlayTrack(index)
            }
          }
        }
    }, [tracks]);

  useEffect(() => {
    if (play_track != null) {
      console.log("Play Track Changed:", play_track.title)
      // Find the index of the track with a matching ID or other identifier
      const index = tracks.findIndex((track) => track.video_id === play_track.video_id);

      if (index !== -1) {
        // Call your handlePlayTrack function with the index and play_track
        handlePlayTrack(index);
      }
    }
  }, [play_track, tracks]);

  useEffect(() => {
      if (nowPlayingIndex >= 0) {
        console.log("Now Playing changed to:", nowPlayingIndex, " title:", nowPlayingTitle)
        updateMediaSessionMetadata()
      }
  }, [nowPlayingIndex]);

  const updateMediaSessionMetadata = () => {
      navigator.mediaSession.metadata = new MediaMetadata({
          title: nowPlayingTitle,
          artist: nowPlayingArtist || "Unknown Artist",
          album: nowPlayingArtist || "Unknown Album",
          artwork: [
              { src: nowPlayingThumbnail || "default-image.jpg", sizes: "512x512", type: "image/jpeg" }
          ]
      });
  };

  const setupMediaSessionActions = () => {
      navigator.mediaSession.setActionHandler("play", () => audioElementRef.current.play());
      navigator.mediaSession.setActionHandler("pause", () => audioElementRef.current.pause());
      navigator.mediaSession.setActionHandler("nexttrack", gotoNextTrack);
      navigator.mediaSession.setActionHandler("previoustrack", gotoPreviousTrack);
  };

  const gotoPreviousTrack = () => {
    if (tracks.length == 0) {
      return;
    }
    console.log("PlayTrack: ", nowPlayingIndex, " length:", tracks.length);
    let nextPlayingIndex = nowPlayingIndex - 1
    if (nowPlayingIndex == -1) {
        nextPlayingIndex = 0
    }
    else {
      if (nextPlayingIndex < 0) {
        nextPlayingIndex = tracks.length - 1
      }
    }
    handlePlayTrack(nextPlayingIndex);
  }

  const gotoNextTrack = () => {
    if (tracks.length == 0) {
      return;
    }
    console.log("PlayTrack: ", nowPlayingIndex, " length:", tracks.length);
    let nextPlayingIndex = nowPlayingIndex + 1
    if (tracksShuffled) {
      nextPlayingIndex = 0
    }
    nextPlayingIndex = nextPlayingIndex % tracks.length;
    handlePlayTrack(nextPlayingIndex);
  }

  const shuffleTracks = () => {
    if (tracks.length == 0) {
      return;
    }
    let shuffle = [...tracks]
      // Fisher-Yates shuffle algorithm
    for (let i = shuffle.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffle[i], shuffle[j]] = [shuffle[j], shuffle[i]];
    }
    for (let i = shuffle.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffle[i], shuffle[j]] = [shuffle[j], shuffle[i]];
    }
    initializeTracks(shuffle)
    setTracksShuffled(true)
  }

  const updateAudioMedia = (index, url) => {
    if (index === -1) {
        return
    }
    const track = tracks[index]
    setNowPlayingTitle(track.title)
    setNowPlayingVideoId(track.video_id)
    setNowPlayingArtist(track.artist)
    setNowPlayingThumbnail(track.thumbnail_default)
    setNowPlayingIndex(index); // Update the current playing track index
    localStorage.setItem("walmates-now-playing-video-id", track.video_id)

    // preload next track
    const nextIndex = (index + 1) % tracks.length
    cacheNextTrack(tracks[nextIndex])

    const audio = audioElementRef.current

    if(!audio) {
      return
    }

    audio.pause()
    setAudioSrc(url); // Set the new audio source

    // Update the source
    audio.src = url

    try {
      audio.play();
      console.log("Playing track: ", track.title)
    } catch(error) {
      console.log("Error playing track: ", track.title, " error:", error)
    }
  }

  const handleDeleteTrack = async(index, track) => {
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');
    server.post(`/api/music/deleteTrack?user_id=${user_id}&video_id=${track.video_id}`, null, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log('Track Deleted: ', result);
      const updatedTracklist = tracks.filter((track) => track.video_id !== track.video_id);
      initializeTracks(updatedTracklist);
      console.log("Delete Track: ", track.title)
    })
    .catch(err => {
      console.error('Error in deleting track:', track.title, 'error:', err);
    });
  }

  const cacheNextTrack = async (track) => {
    if (cachedTracksRef.current[track.video_id]) {
      console.log(`Track-${track.video_id} already cached.`)
      return
    }

    const token = await localStorage.getItem('walmates-token');
    const user_id = await localStorage.getItem('walmates-user_id');
    const response = await server.get(`/api/music/playTrack?user_id=${user_id}&name=${track.name}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
      .then(async (response) => {
        const blob = new Blob([response.data], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(blob);
        cachedTracksRef.current[track.video_id] = url;
        console.log(`PreCached Track-${track.video_id}`)
      })
      .catch((err) => {
        console.error("cacheNextTrack: Error in caching video:", track.video_id, " error:", err)
      });
  };

  const handlePlayTrack = async (index) => {
    console.log("handlePlayTrack index:", index, " nowPlayingIndex:", nowPlayingIndex)
    // disable tracks shuffling
    setTracksShuffled(false)

    // get the track
    const track = tracks[index]
    if (track === null) {
        console.log("Track not found at index", index)
        return;
    }
    if (track.video_id == nowPlayingVideoId) {
      console.log("Already playing same track, request ignored");
      return;
    }
    console.log("handlePlayTrack track to play:", track.title)

    if (cachedTracksRef.current[track.video_id] != null) {
      console.log("Playing from cache..")
      updateAudioMedia(index, cachedTracksRef.current[track.video_id])
    }
    else {
      const token = await localStorage.getItem('walmates-token');
      const user_id = await localStorage.getItem('walmates-user_id');
      server
        .get(`/api/music/playTrack?user_id=${user_id}&name=${track.name}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        })
        .then(async (response) => {
          console.log("PlayTrack: ", index, " title:", track.title, " total:", tracks.length);
          const blob = new Blob([response.data], { type: 'audio/mpeg' });
          const url = URL.createObjectURL(blob);
          console.log(`Cached Track-${track.video_id}`)
          cachedTracksRef.current[track.video_id] = url;
          updateAudioMedia(index, url);
        })
        .catch((err) => {
          console.error("Error in playing track:", track.title, ' error:', err);
        });
    }
  };

  const onTagSelectionChange = async(selectedTags) => {
    console.log("Selected Tags:", selectedTags)
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');

    const queryString = selectedTags.map(tag => `tags=${encodeURIComponent(tag)}`).join('&');
    const url = `/api/music/getTracks?user_id=${encodeURIComponent(user_id)}&${queryString}`;
    server.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      initializeTracks(result.data);
      setInitialized(true)
    })
    .catch(err => {
      console.error("Error to search ", keyword, " error:", err);
    });
  }

  useEffect(() => {
    // Add the ended event listener to the audio element
    if (initialized == false) {
      return;
    }

    if ("mediaSession" in navigator) {
      updateMediaSessionMetadata();
      setupMediaSessionActions();
    }

    const handleEnding = () => {
      const audio = audioElementRef.current
      let nextPlayingIndex = (nowPlayingIndex + 1) % tracks.length;
      const nextTrack = tracks[nextPlayingIndex]
      const timeout = (device === 'desktop')? 0 : 1
      if (audio && audio.duration - audio.currentTime <= timeout) {
        console.log("handle Ending Triggered before", timeout, "seconds")
        audio.removeEventListener('timeupdate', handleEnding); 
        handleEnded()
      }
    };

    const handleEnded = () => {
      let nextPlayingIndex = (nowPlayingIndex + 1) % tracks.length;
      if (tracksShuffled) {
        nextPlayingIndex = 0
      }
      console.log("Track ended play next Track: ", nowPlayingIndex)
      handlePlayTrack(nextPlayingIndex, tracks[nextPlayingIndex]);
    };

    audioElementRef.current.addEventListener('timeupdate', handleEnding);
    audioElementRef.current.addEventListener('ended', handleEnded);

    // Clean up the event listener when the component unmounts
    return () => {
      if (audioElementRef.current) {
          audioElementRef.current.removeEventListener('timeupdate', handleEnding);
          audioElementRef.current.removeEventListener('ended', handleEnded);
      }
    };
  }, [initialized, nowPlayingIndex]);
    

  return (
    <>
      <div style={{ background: "#f3f3f3"}}>
        <div>
          {nowPlayingIndex >= 0 ? 
            <li className="track-item" style={{background:"#333", color:"white", marginBottom:"0px", borderBottom:null}}>
              <div className="track-image">
                <img src={nowPlayingThumbnail}/>
              </div>
              <div className="track-details">
                <h3>{nowPlayingTitle}</h3>
                <p>{nowPlayingArtist}</p>
              </div>
            </li>
         : null}  
          <div className="audio-controls" style={{background:"#eee"}}>
            <Button style={{color:'#333'}} onClick={shuffleTracks}>
             <ShuffleIcon/>
            </Button>
            <Button style={{color:'#333'}} onClick={gotoPreviousTrack}>
             <SkipPreviousIcon/>
            </Button>
            <Button style={{color:'#333'}} onClick={gotoNextTrack}>
             <SkipNextIcon/>
            </Button>
            <audio ref={audioElementRef} controls playsInline>
              <source src={audioSrc} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
        { tags.length > 0 ? (<TagList tags={tags} hearts={true} onSelectionChange={onTagSelectionChange} mode={"checkbox"} />) : null }
        <TrackList tracks={tracks} selectedTags={tags} onTrackClick={handlePlayTrack} onTrackDelete={handleDeleteTrack} />
      </div>
    </>
  );
};

const SearchWidget = (props) => {
  const {addTrack, playLocalTrack} = props
  const [tracks, setSearchResult] = useState([]);
  const [local_tracks, setLocalSearchResult, setPlayLocalTrack] = useState([]);
  const [keyword, setKeyword] = useState('');

  const handleKeywordEdit = (nkw) => {
    setKeyword(nkw);
    if (nkw.length > 2) {
      props.handleLocalSearch(nkw, setLocalSearchResult);
    }
    else {
      setLocalSearchResult([])
      setSearchResult([])
    }
  };

  const handlePlayLocalTrack = (index, track) => {
    playLocalTrack(track)
    setLocalSearchResult([])
    setKeyword('')
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch(keyword);
    } 
  };


  const handleSearch = (search_string) => {
    let token = localStorage.getItem('walmates-token');
    server.get(`/api/music/search?title=${search_string}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(result => {
        // console.log("Search Results", result);
        // Update search_results state or perform other actions with the result data
        setSearchResult(result.data);
      })
      .catch(err => {
        console.error("Error to search ", search_string, " error:", err);
      });
  };

  const handleAddTrack = (index, param) => {
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');
    
    // Check if token and user_id exist
    if (!token || !user_id) {
      console.error('Token or user_id not found in localStorage');
      return;
    }
    console.log("Adding Track: ", param.title)
    setKeyword('')
    setSearchResult([])

    // Set user_id in the param object
    server.post(`/api/music/addTrack?user_id=${user_id}`, param, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log('Track Added: ', result);
      addTrack(result.data)
    })
    .catch(err => {
      console.error('Error in downloading:', param.title, 'error:', err);
    });
  }

  const handleDeleteTrack = async(index, track) => {
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');
    server.post(`/api/music/deleteTrack?user_id=${user_id}&video_id=${track.video_id}`, null, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log('Track Deleted: ', result);
      const updatedTracklist = tracks.filter((track) => track.video_id !== track.video_id);
      setLocalSearchResult(updatedTracklist);
      console.log("Delete Track: ", track.title)
    })
    .catch(err => {
      console.error('Error in deleting track:', track.title, 'error:', err);
    });
  }

  return (
    <>
      <div>
        <input
          type="text"
          className="falcon_text"
          enterkeyhint="done"
          value={keyword}
          style={{ fontSize: "20px", color: "white", padding: "10px", background:"#333"}}
          placeholder="Search Songs, Artist.."
          onChange={(e) => { handleKeywordEdit(e.target.value) }}
          onKeyDown={handleKeyDown} />
      </div>
      {local_tracks.length > 0 && (
        <div style={{background:"#aaa", padding:"13px"}}>
            <TrackList tracks={local_tracks} selectedTags={[]} onTrackClick={handlePlayLocalTrack} onTrackDelete={handleDeleteTrack}/>
         </div>
      )}
      {tracks.length > 0 && (
        <div style={{background:"#aaa", padding:"13px"}}>
            <TrackList tracks={tracks} selectedTags={[]} onTrackClick={handleAddTrack}/>
         </div>
      )}
    </>
  );
};

const TrackList = ({ tracks, selectedTags, onTrackClick, onTrackDelete }) => {

  const ShareButton = ({url}) => {
    const handleShare = async () => {
      const shareData = {
        title: "Check out this track!",
        text: "Hey, listen to this track:",
        url: url,
      };

      if (navigator.share) {
        try {
          await navigator.share(shareData);
          console.log("Content shared successfully!");
        } catch (err) {
          console.error("Error sharing content:", err);
        }
      } else {
        alert("Sharing is not supported on your browser. Copy the link: " + url);
      }
    };

    return (
      <FontAwesomeIcon
        icon={faShare}
        onClick={handleShare}
        style={{ cursor: "pointer", fontSize: "24px", color: "#007BFF", marginTop:"5px", marginLeft:"10px",color:"green"}}
        title="Share"
      />
    );
  };

  const onTrackUpdate = (index, track, field, value) => {
    console.log("Updating Track:", track.title, " field:", field, " value:", value)
    let token = localStorage.getItem('walmates-token');
    let user_id = localStorage.getItem('walmates-user_id');
    server.post(`/api/music/updateTrack?user_id=${user_id}&video_id=${track.video_id}&field=${field}&value=${value}`, null, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(result => {
      console.log('Track[', track.title, '] Field:', field, " => ", value);
    })
    .catch(err => {
      console.error('Error in updating track:', track.title, 'error:', err);
    });
  }

  const checkTags = (track) => {
    let tagStr = ""
    if (track.tag != null) {
        tagStr = track.tag.trim()
    }
    if (selectedTags === null || selectedTags.length === 0) {
      return true
    }
    if (tagStr == null || tagStr.length == 0) {
      return true
    }
    const tagsArray = tagStr.split(',');
    return tagsArray.some(tag => selectedTags.includes(tag.trim()));
  }

  return (
    <ul className="track-list">
      {tracks.map((track, index) => (
          (checkTags(track)) ? (
            <li key={index} className="track-item">
              <div className="track-image" onClick={() => onTrackClick(index, track)}>
                <img src={track.thumbnail_default} alt={`Track ${index}`} />
              </div>
              <div className="track-details">
                <h3>{index+1}.  {track.title}</h3>
                <p>Artist: {track.artist}</p>
                <div style={{display:"flex"}}>
                { onTrackDelete != null ? 
                    <TagEditList item={track} use_heart={true} onTagChange={(newTag) => onTrackUpdate(index, track, "tag", newTag)} />
                    : null}
                { navigator.share ? <ShareButton url={track.url}/> : null }
                  {onTrackDelete != null ?
                  <div className="delete-icon" onClick={() => onTrackDelete(index, track)} style={{color:"red", marginTop:"5px", marginLeft:"10px", cursor:"pointer"}}>
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                  : null}
                </div>
              </div>
            </li>
          ) : null
      ))}
    </ul>
  );

  // Define a function to handle delete click if needed
  const onDeleteClick = (index) => {
    // Handle the delete action here, e.g., by passing the index to a delete function.
  };
};

